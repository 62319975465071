import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, message, Radio, Row, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CustomModal, InputField, Spinner } from "../components/common";
import PhoneInput from "antd-phone-input";
import TextArea from "antd/es/input/TextArea";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import {
  deleteUser,
  fetchAllDevices,
  fetchAllUsers,
  updateUser,
  usersRegister,
} from "../lib/api";
import { useNavigate } from "react-router-dom";
import { setDevices } from "../store/slices/devicesSlice";
import { useTranslation } from "react-i18next";
import {
  EditOutlined,
  SendOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

export const UserRegister = () => {
  const [selectionType, setSelectionType] = useState("checkbox");
  const [loading, setLoading] = useState(false);
  const [selectedLocationRowKeys, setSelectedLocationRowKeys] = useState([]);
  const [selectedModalLocationRowKeys, setSelectedModalLocationRowKeys] =
    useState([]);
  const [locationDataSource, setLocationDataSource] = useState([]);
  const [userInput, setUserInput] = useState({});
  const [notifications, setNotifications] = useState({});
  const [selectedCountry, setSelectedCountry] = useState("ua");
  const [selectedUserRowKey, setSelectedUserRowKey] = useState("");
  const [selectedUserRow, setSelectedUserRow] = useState({});
  const [userDataSource, setUserDataSource] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInput, setModalInput] = useState({});
  const [locationModalDataSource, setLocationModalDataSource] = useState([]);

  const { devices } = useSelector((state) => state.devices);
  const { cTheme } = useSelector((state) => state.cTheme);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Table columns
  const usersColumns = [
    {
      title: "",
      width: 30,
      render: (_, record) => (
        <Radio
          checked={selectedUserRowKey === record.key}
          onChange={() => handleUserRowSelect(record.key, record)}
        />
      ),
    },
    {
      width: 85,
      title: "Nick Name",
      dataIndex: "nick_name",
    },
    {
      width: 90,
      title: "First Name",
      dataIndex: "first_name",
    },
    {
      width: 70,
      title: "Role",
      dataIndex: "role",
    },
    {
      width: 190,
      title: "Email",
      dataIndex: "email",
    },
    {
      width: 85,
      title: "Second Name",
      dataIndex: "second_name",
    },
    {
      width: 170,
      title: "Phone",
      dataIndex: "phone",
    },
    {
      width: 100,
      title: "Telegram",
      dataIndex: "telegram",
    },
    {
      title: "Viber",
      dataIndex: "viber",
    },
  ];

  const locationColumns = [
    {
      title: "Location ID",
      dataIndex: "locationId",
    },
    {
      title: "Place",
      dataIndex: "place",
    },
  ];
  ///////////////////////////////////////////

  // Interact with backend
  const getAllDevices = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchAllDevices();
      if (response.ok) {
        dispatch(setDevices(response.data));
      }
    } catch (err) {
      console.error("Server error:", err);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  const getAllUsers = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchAllUsers();
      if (response.ok) {
        const dataSource = response.data.map((dt) => ({
          ...dt,
          key: dt.id,
          created_at: new Date(dt.created_at).toISOString().split("T")[0],
        }));
        setUserDataSource(dataSource);
      }
    } catch (err) {
      message.error(`${t("badRequest getAllUsers")}`);
    } finally {
      setLoading(false);
    }
  }, [t]);

  // Handle change
  const handleUserRowSelect = (key, record) => {
    setSelectedUserRowKey(key === selectedUserRowKey ? null : key);
    setSelectedUserRow(record);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedLocationRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "",
      name: record.name,
    }),
  };

  const modalRowSelection = {
    selectedRowKeys: selectedModalLocationRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedModalLocationRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "",
      name: record.name,
    }),
  };

  const handleUserInputChange = (e) =>
    setUserInput({ ...userInput, [e.target.name]: e.target.value });

  const handleModalInputChange = (e) => {
    setModalInput({ ...modalInput, [e.target.name]: e.target.value });
  };

  const handleNotificationsChange = (e) =>
    setNotifications({ ...notifications, [e.target.name]: e.target.value });

  const handlePhoneChange = (value, key) => {
    const { areaCode, countryCode, phoneNumber } = value || {};
    const phoneString =
      areaCode && phoneNumber
        ? `+${countryCode} (${areaCode}) ${phoneNumber}`
        : "";
    if (key === "modal") {
      setModalInput({ ...modalInput, phone: phoneString });
    } else {
      setUserInput({ ...userInput, phone: phoneString });
    }
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      if (selectedUserRow.key) {
        const data = {
          id: selectedUserRow.key,
          locationIds: selectedUserRow.locations,
        };
        const response = await deleteUser(data);
        if (response.ok) {
          message.success(response.message);
          const dataSource = userDataSource.filter(
            (uds) => uds.key !== selectedUserRow.key
          );
          setUserDataSource(dataSource);
          setSelectedUserRow({});
          setSelectedUserRowKey("");
        }
      }
    } catch (err) {
      message.error(t("badRequest handleDelete"));
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = () => {
    if (selectedUserRow.key) {
      const user = { ...selectedUserRow };
      setModalInput(user);
      setModalOpen(true);
      setLocationModalDataSource(locationDataSource);
      if (user.locations) {
        const { locations } = user;
        const rowKeys = locations.map((location) => location.location_id);
        setSelectedModalLocationRowKeys(rowKeys);
      }
    } else {
      message.info("Please select a row in user table");
    }
  };

  const handleModalCancle = () => {
    setModalInput({});
    setModalOpen(false);
    setSelectedModalLocationRowKeys([]);
  };

  const handleModalOk = async () => {
    try {
      setLoading(true);
      const data = {
        ...modalInput,
        id: modalInput.key,
        locationIds: selectedModalLocationRowKeys,
        originLocationIds: selectedUserRow.locations,
      };
      const response = await updateUser(data);
      if (response.ok) {
        message.success(response.message);
        const locations = selectedModalLocationRowKeys.map((key) => ({
          location_id: key,
        }));
        const dataSource = userDataSource.map((uds) =>
          uds.key === selectedUserRow.key
            ? {
                ...modalInput,
                locations: locations,
              }
            : uds
        );
        setUserDataSource(dataSource);
        setSelectedUserRow({
          ...modalInput,
          locations: locations,
        });
        setSelectedUserRowKey(modalInput.key);
      }
    } catch (err) {
      message.error(t("badRequest handleModalOk"));
    } finally {
      setLoading(false);
      setModalOpen(false);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const data = {
        ...userInput,
        locationIds: selectedLocationRowKeys,
      };
      const response = await usersRegister(data);
      if (response.ok) {
        message.success("Added successfully");
      }
    } catch (err) {
      message.error(t("badRequest handleSave"));
    } finally {
      setLoading(false);
    }
  };
  ///////////////////////////////////////////

  // Validation
  const isValid = () => {
    const { nick_name, first_name, second_name, email, telegram, viber, phone, password, role } =
      userInput;
    if (
      !nick_name ||
      nick_name === "" ||
      !first_name ||
      first_name === "" ||
      !second_name ||
      second_name === "" ||
      !email ||
      email === "" ||
      !telegram ||
      telegram === "" ||
      !viber ||
      viber === "" ||
      !password ||
      password === "" ||
      !role ||
      role === "" 
    ) {
      return false;
    }

    const parsedPhoneNumber = parsePhoneNumberFromString(
      phone,
      selectedCountry
    );
    if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
      return false;
    }
    if (selectedLocationRowKeys.length === 0) {
      return false;
    }
    return true;
  };

  const isModalValid = () => {
    const { nick_name, first_name, second_name, email, telegram, viber, phone, role } = modalInput;
    if (
      !nick_name ||
      nick_name === "" ||
      !first_name ||
      first_name === "" ||
      !second_name ||
      second_name === "" ||
      !email ||
      email === "" ||
      !telegram ||
      telegram === "" ||
      !viber ||
      viber === "" ||
      !role ||
      role === ""
    ) {
      return false;
    }

    const parsedPhoneNumber = parsePhoneNumberFromString(
      phone,
      selectedCountry
    );
    if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
      return false;
    }
    if (selectedModalLocationRowKeys.length === 0) {
      return false;
    }
    return true;
  };
  //////////////////////////////////////////////////

  // Hooks
  useEffect(() => {
    getAllDevices();
    getAllUsers();
  }, [getAllDevices, getAllUsers]);

  useEffect(() => {
    if (devices.length > 0) {
      const dataSource = devices
        .filter((device) => device.active === 1)
        .map((device) => ({
          key: device.id,
          locationId: device.id,
          place: device.place,
        }));
      setLocationDataSource(dataSource);
    }
  }, [devices]);
  //////////////////////////////////////////////////

  if (loading) {
    return <Spinner />;
  }
//Current Users
  return (
    <div style={{ padding: 30 }}>
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ color: `${cTheme ? "white" : "black"}` }}>
            Current Users
          </h1>
          <Table
            dataSource={userDataSource}
            columns={usersColumns}
            pagination={false}
            scroll={{
              x: 1500,
              y: 800,
            }}
            style={{ marginTop: 30 }}
          />
        </Col>
        <Col span={4}>
          <h1 style={{ color: `${cTheme ? "white" : "black"}` }}>
            Possible locations
          </h1>
          <Table
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            columns={locationColumns}
            dataSource={locationDataSource}
            style={{ marginTop: 30 }}
            pagination={false}
            scroll={{ y: 800 }}
          />
        </Col>        
        <Col span={4}>
          <h1 style={{ color: `${cTheme ? "white" : "black"}` }}>
            Add New User</h1>
          <div>
            <InputField
              name="nick_name"
              type="text"
              placeholder="Nick name"
              onChange={handleUserInputChange}
            />
            <InputField
              name="first_name"
              type="text"
              placeholder="First Name"
              onChange={handleUserInputChange}
            />
            <InputField
              name="second_name"
              type="text"
              placeholder="Second Name"
              onChange={handleUserInputChange}
            />
            <InputField
              name="email"
              type="email"
              placeholder="E-mail"
              onChange={handleUserInputChange}
            />
            <div style={{ marginTop: 20 }}>
              <div style={{ marginBottom: 5 }}>
                <label
                  style={{
                    fontSize: "1em",
                    color: `${cTheme ? "white" : "black"}`,
                  }}
                >
                  Phone Number
                </label>
              </div>
              <PhoneInput
                country={selectedCountry}
                onCountryChange={handleCountryChange}
                name="phone"
                value={userInput.phone}
                onChange={(v) => handlePhoneChange(v, "user")}
                required
              />
            </div>
           <InputField
              name="telegram"
              type="text"
              placeholder="Telegram"
              onChange={handleUserInputChange}
            />
            <InputField
              name="viber"
              type="text"
              placeholder="Viber"
              onChange={handleUserInputChange}
            />
            <InputField
              name="password"
              type="password"
              placeholder="Password"
              onChange={handleUserInputChange}
            />
            <InputField
              name="role"
              type="text"
              placeholder="Role"
              onChange={handleUserInputChange}
            />
             <div style={{ marginTop: 20 }}>
              <div style={{ marginBottom: 5 }}>
                <label
                  style={{
                    fontSize: "1em",
                    color: `${cTheme ? "white" : "black"}`,
                  }}
                >
                  Something else
                </label>
              </div>
              <TextArea
                name="somthingElse"
                rows={4}
                onChange={handleUserInputChange}
              />
            </div>
          </div>
        </Col>
        <Col span={2}>
          <h1 style={{ color: `${cTheme ? "white" : "black"}` }}>
            Notifications
          </h1>
          <InputField
            name="telegram"
            type="text"
            placeholder="Telegram"
            onChange={handleNotificationsChange}
            disabled={false}
          />
          <InputField
            name="viber"
            type="text"
            placeholder="Viber"
            onChange={handleNotificationsChange}
            disabled={false}
          />
          <InputField
            name="sms"
            type="text"
            placeholder="SMS"
            onChange={handleNotificationsChange}
            disabled={false}
          />
          <InputField
            name="email"
            type="text"
            placeholder="Email"
            onChange={handleNotificationsChange}
            disabled={false}
          />
        </Col>
      </Row>
      <div style={{ textAlign: "right", width: "100%" }}>
        <Button
          color="danger"
          variant="solid"
          style={{ marginRight: 20 }}
          onClick={handleDelete}
        >
          {t("delete")}
          <UsergroupAddOutlined style={{ marginLeft: 3 }} />
        </Button>
        <Button
          color="primary"
          variant="solid"
          style={{ marginRight: 20 }}
          onClick={handleEdit}
        >
          {t("edit")}
          <EditOutlined style={{ marginLeft: 3 }} />
        </Button>
        <Button
          color="primary"
          variant="solid"
//          disabled={!isValid(userInput)}
          onClick={handleSave}
        >
          {t("save")}
          <SendOutlined style={{ marginLeft: 3 }} />
        </Button>
      </div>
      <CustomModal
        title="User Edit"
        confirmLoading={confirmLoading}
        open={modalOpen}
        handleCancel={handleModalCancle}
//        disabledOk={!isModalValid()}
        handleOk={handleModalOk}
      >
        <Row gutter={16}>
          <Col span={14}>
            <Table
              rowSelection={{
                type: selectionType,
                ...modalRowSelection,
              }}
              columns={locationColumns}
              dataSource={locationModalDataSource}
              pagination={false}
              scroll={{ y: 800 }}
            />
          </Col>
          <Col span={10}>
            <div>
              <InputField
                name="nick_name"
                type="text"
                placeholder="Nick name"
                value={modalInput.nick_name}
                onChange={handleModalInputChange}
              />
              <InputField
                name="first_name"
                type="text"
                placeholder="First Name"
                value={modalInput.first_name}
                onChange={handleModalInputChange}
              />
              <InputField
                name="second_name"
                type="text"
                placeholder="Second Name"
                value={modalInput.second_name}
                onChange={handleModalInputChange}
              />
              <InputField
                name="email"
                type="email"
                placeholder="E-mail"
                value={modalInput.email}
                onChange={handleModalInputChange}
              />
              <div style={{ marginTop: 20 }}>
                <div style={{ marginBottom: 5 }}>
                  <label
                    style={{
                      fontSize: "1em",
                      color: `${cTheme ? "white" : "black"}`,
                    }}
                  >
                    Phone Number
                  </label>
                </div>
                <PhoneInput
                  country={selectedCountry}
                  onCountryChange={handleCountryChange}
                  name="phone"
                  value={modalInput.phone}
                  onChange={(v) => handlePhoneChange(v, "modal")}
                  required
                />
              </div>
              <InputField
                name="telegram"
                type="text"
                placeholder="Telegram"
                value={modalInput.telegram}
                onChange={handleModalInputChange}
              />
              <InputField
                name="viber"
                type="text"
                placeholder="Viber"
                value={modalInput.viber}
                onChange={handleModalInputChange}
              />
              <InputField
                name="password"
                type="password"
                placeholder="Password"
                onChange={handleModalInputChange}
              />
               <InputField
                name="role"
                type="text"
                placeholder="Role"
                value={modalInput.viber}
                onChange={handleModalInputChange}
              />
            </div>
          </Col>
        </Row>
      </CustomModal>
    </div>
  );
};