import React, { useState } from "react";
import {
  Col,
  DatePicker,
  Row,
  Space,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setSelectedDate } from "../store/slices/selectedDataSlice";
//import { getDateWithISO } from "./constant/func";



export const DateSelector = () => {
  const { selectedData } = useSelector((state) => state.selectedData);
//  const { cTheme } = useSelector((state) => state.cTheme);
//  const { t } = useTranslation();
  const dispatch = useDispatch();
   
  // Handle changes
  const handleDatePickerChange = async (dateSelected, dateString ) => {
    if (dateSelected) {
    dispatch(setSelectedDate(dateSelected));
    }
  };
  return (
    <div style={{ padding: 20 }}>
          <DatePicker
            style={{ display: "block" }}
            onChange={handleDatePickerChange}
            value={selectedData.date}
          />
    </div>
  )
}
/*const handleDatePickerChange = async (date) => {
    setDate(date);
    if (date) {
      dispatch(setSelectedDate(date));
      const formattedDate = getDateWithISO(date);
      const data = await getAllSettings({
        locations: user.locations,
        date: formattedDate,
      });
      if (data) {
        setMozaicData(data.setting);
        if (data.compareCnts.length > 0) {
          setCompared(true);
/*          const group = await getGroupByBadDataCnt(
            data.compareCnts[data.compareCnts.length - 1].cnt
          );
          if (group && group.id) {
            setBadDataGroup(group);
          }
        } else {
          setCompared(false);
        }
      }
    }
*/