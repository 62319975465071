import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import devicesReducer from "./slices/devicesSlice";
import channelsReducer from "./slices/channelsSlice";
import selectedDataReducer from "./slices/selectedDataSlice";
import settingsReducer from "./slices/settingsSlice";
import themeReducer from "./slices/themeSlice";
import tvReducer from "./slices/tvSlice";
import groupReducer from "./slices/groupSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    devices: devicesReducer,
    channels: channelsReducer,
    selectedData: selectedDataReducer,
//     {
//    selectedLocation:  setSelectedLocation,
//    selectedTvType:  setSelectedTvType,
//    selectedSetting:  setSelectedSetting,
//    selectedProgram:  setSelectedProgram,
//    selectedDate:  setSelectedDate,
//    },
    settings: settingsReducer,
    cTheme: themeReducer,
    selectorTV: tvReducer,
    groups: groupReducer,
  },
});

export default store;
