import axiosInstance from "../axiosInstance";

export const fetchAllDevices = async () => {
  const response = await axiosInstance.get("/devices/get/all");
  return response.data;
};

export const fetchDevicesById = async (locations) => {
  const response = await axiosInstance.post("/devices/get-by-id", locations);
//  const response = await axiosInstance.get(`/devices/${locations}`);
  return response.data;
};

export const updateDevice = async (data) => {
  const response = await axiosInstance.patch("/devices/update-one", data);
  return response.data;
};
