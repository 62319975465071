import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
//  role: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
//      state.role = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
