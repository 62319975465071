import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedData: {
//    location: null,
//    tvType: null,
//    setting: null,
    program: null,
    date: null,
  },
};

const selectedDataSlice = createSlice({
  name: "selectedData",
  initialState,
  reducers: {
/*    setSelectedLocation(state, action) {
      state.selectedData.location = action.payload;
    },
    setSelectedTvType(state, action) {
      state.selectedData.tvType = action.payload;
    },
    setSelectedSetting(state, action) {
      state.selectedData.setting = action.payload;
    },
*/    
    setSelectedProgram(state, action) {
      state.selectedData.program = action.payload;
    },
   setSelectedDate(state, action) {
      state.selectedData.date = action.payload;
    },
  },
});

export const {
//  setSelectedLocation,
//  setSelectedTvType,
//  setSelectedSetting,
  setSelectedProgram,
  setSelectedDate,
} = selectedDataSlice.actions;
export default selectedDataSlice.reducer;
