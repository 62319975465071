import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Dropdown,
  Layout,
  Menu,
  message,
  Switch,
  theme,
} from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  BellOutlined,
  DownOutlined,
  MoonOutlined,
  SettingOutlined,
  SunOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../store/slices/userSlice";
import { setLight, setDark } from "../../store/slices/themeSlice";
import { setAuthToken } from "../../lib/axiosInstance";
import { LanguageSwitcher } from "../common";
import { useTranslation } from "react-i18next";

const { Header } = Layout;

export const Navbar = ({ setMessageOpen, messageCounts }) => {
  const { t } = useTranslation();

  const [navMenu, setNavMenu] = useState([]);

  const adminMenu = [
    { label: `${t("main")}`, path: "/main" },
    { label: `${t("chart")}`, path: "/chart" },
    { label: `${t("table")}`, path: "/table" },
    { label: `${t("video")}`, path: "/video" },
    { label: `${t("compare")}`, path: "/compare" },
    { label: `${t("devices")}`, path: "/devices" },
    { label: `${t("analog")}`, path: "/analog-setting" },
    { label: "DVB-T2", path: "/dvb-t2-setting" },
    { label: "DVB-C", path: "/dvb-c-setting" },
    { label: "IPTV", path: "/iptv-setting" },
    { label: `${t("sequence")}`, path: "/sequence" },
    { label: `${t("groups")}`, path: "/groups" },
    { label: `${t("schedules")}`, path: "/schedules" },
    { label: `${t("users")}`, path: "/users" },
  ];

  const userMenu = [
    { label: `${t("main")}`, path: "/main" },
    { label: `${t("chart")}`, path: "/chart" },
    { label: `${t("table")}`, path: "/table" },
    { label: `${t("video")}`, path: "/video" },
    { label: `${t("compare")}`, path: "/compare" },
  ];

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [username, setUsername] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const { cTheme } = useSelector((state) => state.cTheme);

  const selectedKey = navMenu
    .findIndex((item) => item.path === location.pathname)
    .toString();

  const handleLogout = () => {
    localStorage.removeItem("tv_monitor_token");
    localStorage.removeItem("groups");
    localStorage.removeItem("selectorTV");
    dispatch(setUser(null));
    navigate("/auth/login");
    setAuthToken(null);
    message.warning("You log out");
  };

  const LogoutMenu = (
    <Menu>
      <Menu.Item key="1" style={{ padding: "5px 20px" }}>
        <Button
          type="link"
          style={{ color: `${cTheme ? "white" : "black"}` }}
          onClick={handleLogout}
        >
          {t("logout")}
        </Button>
      </Menu.Item>
    </Menu>
  );

  const settingMenu = (
    <Menu>
      <Menu.Item key="1" style={{ padding: "5px 20px" }}>
        <Switch
          checkedChildren={<SunOutlined />}
          unCheckedChildren={<MoonOutlined />}
          defaultChecked={true}
          onChange={(v) => {
//            debugger;
            if (v) {
              dispatch(setDark());
            } else {
              dispatch(setLight());
            }
          }}
        />
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (user && user.id) {
      setNavMenu(user.role === "admin" ? adminMenu : userMenu);
      setUsername(`${user.nick_name ? user.nick_name : "NONE"}`);
    }
  }, [user]);

  return (
    <Layout>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: 64,
          backgroundColor: `${cTheme ? "#000000" : "#124381"}`,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "70%",
          }}
        >
          <div className="demo-logo">
            <h1 style={{ color: "white" }}>{t("tvMonitor")}</h1>
          </div>
          <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={[selectedKey]}
            style={{
              flex: 1,
              minWidth: 0,
              marginLeft: 20,
              backgroundColor: `${cTheme ? "#000000" : "#124381"}`,
            }}
          >
            {navMenu.map((item, index) => (
              <Menu.Item key={index} style={{ color: "white" }}>
                <Link to={item.path} style={{ color: "white" }}>
                  {item.label}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            alignItems: "center",
            height: "100%",
          }}
        >
          <LanguageSwitcher />
          <Badge count={messageCounts}>
            <Button
              style={{
                borderRadius: "50%",
                width: 30,
                height: 30,
              }}
              type="text"
              onClick={() => setMessageOpen(true)}
            >
              <BellOutlined color="white" />
            </Button>
          </Badge>
          <Dropdown overlay={settingMenu} trigger={["click"]}>
            <Button
              type="text"
              style={{
                borderRadius: "50%",
                width: 30,
                height: 30,
              }}
            >
              <SettingOutlined color="white" />
            </Button>
          </Dropdown>
          <Dropdown overlay={LogoutMenu} trigger={["click"]}>
            <button
              style={{
                background: "none",
                color: "white",
                border: "none",
                cursor: "pointer",
                height: "100%",
                display: "flex",
                alignItems: "center",
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <UserOutlined style={{ marginRight: 15 }} />
              <span style={{ fontSize: "1.2em", marginRight: 10 }}>
                {username}
              </span>
              <DownOutlined style={{ marginLeft: 3 }} />
            </button>
          </Dropdown>
        </div>
      </Header>
    </Layout>
  );
};
