import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectorTV: [],
};

const tvSlice = createSlice({
  name: "selectorTV",
  initialState,
  reducers: {
    setSelectorTV(state, action) {
      state.selectorTV = action.payload;
    },
  },
});

export const { setSelectorTV } = tvSlice.actions;
export default tvSlice.reducer;
