import React, { useState } from "react";
import {
  Select,
  Dropdown,
  Col,
  Row,
  Space,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setSelectedProgram } from "../store/slices/selectedDataSlice";
import { viewSelected } from '../lib/viewSelected'; 

export const SelectorDev = () => {
  const { selectorTV } = useSelector((state) => state.selectorTV);
  const [dropdownValue, setDropdownValue] = useState(null);
  const { cTheme } = useSelector((state) => state.cTheme);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDropdownClick2 = ({ key}) => {
    dispatch(setSelectedProgram(key));  
    setDropdownValue(viewSelected(selectorTV, key));
  };

  const devicesOptions = selectorTV.map((location) => ({
    'value': location.key,
    'label': location.label,
  }));
 console.log('devicesOptions>>>', devicesOptions);
  return (
    <Space wrap>
      <Select
        onChange = {handleDropdownClick2}
        style={{ width: 220 }}  
        options={devicesOptions}
      />
    </Space>
  );
};

export const SelectorTV = () => {
  const { selectorTV } = useSelector((state) => state.selectorTV);
  const [dropdownValue, setDropdownValue] = useState(null);
  const { cTheme } = useSelector((state) => state.cTheme);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDropdownClick = ({ key}) => {
    dispatch(setSelectedProgram(key));  
    setDropdownValue(viewSelected(selectorTV, key));
  };
 
  return (
      <div style={{ padding: 20 }}>
      <Row  align="stretch" gutter={16}>
        <Col span={2}>
          <Dropdown
            menu={{ items: selectorTV,
              onClick: handleDropdownClick,
            }}
          >
            <Space
              style={{
                color: `${cTheme ? "white" : "black"}`,
                padding: "5px 10px",
                border: "1px solid #585858",
                borderRadius: "5px",
              }}
            >
              {t("SelectProgram")}
              <DownOutlined />
            </Space>
          </Dropdown>
          </Col>
      </Row>
      <Space
              style={{
                color: `${cTheme ? "white" : "black"}`,
                padding: "5px 10px",
                border: "1px solid #585858",
                borderRadius: "5px",
              }}
      >
      <div>
      {dropdownValue}
      </div>
      </Space>
      </div>
  );
};
 