export function viewSelected(selectorTV, key) {
  try {
//    console.log('key>>>', key, 'selectorTV>>>', selectorTV);
    const separate_stl = key.split(':');
    const splitted = separate_stl[0].split('-');
    const locationIndex = splitted[0];
    //console.log('locationIndex', locationIndex);
    const placeObj = selectorTV.find(item=>item.key.toString() === locationIndex);
    //console.log('placeObj>>>', placeObj);
    const place = placeObj.label;
    var output = place;
    //console.log('place>>>>>', place );
    if (splitted.length > 1) {
      var tvTypeIndex0 = splitted[1];
      if ((tvTypeIndex0 === '2') || (tvTypeIndex0 === '3')){ tvTypeIndex0 = '1'};
      if ((tvTypeIndex0 === '5') || (tvTypeIndex0 === '6')) {tvTypeIndex0 = '4' }; 
      if ((tvTypeIndex0 === '9') || (tvTypeIndex0 === '10')) {tvTypeIndex0 = '8' }; 
      const tvTypeIndex = locationIndex + '-' + tvTypeIndex0;
      //console.log('tvTypeIndex', tvTypeIndex);
      const tvTypeObj = placeObj.children.find(item=>item.key === tvTypeIndex);
      const tvType = tvTypeObj.label;
      output += ' -> '+ tvType;
      //console.log('output>>>', output);
      if (splitted.length > 2) {
        const mxIndex = tvTypeIndex + '-' + splitted[2];
        const mxObj = tvTypeObj.children.find(item=>item.key === mxIndex);//правильно для аналога и iptv 
        const setting = mxObj.label;
        output += ' -> '+ setting;
        //console.log('output>>>', output, 'length>>', splitted.length,  );
        if (splitted.length >3) {
          if ('children' in mxObj) {
            const programIndex = mxIndex + '-' + splitted[3];
            const programObj = mxObj.children.find(item=>item.key === programIndex);
            const program = programObj.label;
            output += ' ->'+ program;
          }
        }
      }
    }
    return output; 
  } 
  catch (err){
    return "No Data" + key;
  }
};

